import { render, staticRenderFns } from "./AddDepartmentComponent.vue?vue&type=template&id=1d72b0af&"
import script from "./AddDepartmentComponent.vue?vue&type=script&setup=true&lang=ts&"
export * from "./AddDepartmentComponent.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SelectWithValidate: require('/home/runner/work/contrea-api/contrea-api/admin/components/SelectWithValidate.vue').default})
