export default defineComponent({
  data: function data() {
    return {
      snackbar: false
    };
  },
  computed: {
    systemErrorMessage: function systemErrorMessage() {
      var message = this.$store.getters.getSystemErrorMessage;
      if (!message) {
        return "";
      }
      this.closeSnackbar();
      this.openSnackbar();
      return message;
    }
  },
  methods: {
    resetSnackbar: function resetSnackbar() {
      this.$store.commit("setSystemErrorMessage", "");
      this.closeSnackbar();
    },
    openSnackbar: function openSnackbar() {
      this.snackbar = true;
    },
    closeSnackbar: function closeSnackbar() {
      this.snackbar = false;
    }
  }
});