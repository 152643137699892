import { render, staticRenderFns } from "./admin.vue?vue&type=template&id=cb57b70e&"
import script from "./admin.vue?vue&type=script&lang=ts&"
export * from "./admin.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SelectWithValidate: require('/home/runner/work/contrea-api/contrea-api/admin/components/SelectWithValidate.vue').default,TextInputWithValidate: require('/home/runner/work/contrea-api/contrea-api/admin/components/TextInputWithValidate.vue').default})
