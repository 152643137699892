export default defineComponent({
  name: "TaskCard",
  props: {
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    buttonText: {
      type: String,
      required: true
    }
  },
  methods: {
    buttonClicked: function buttonClicked() {
      this.$emit("click");
    }
  }
});