import VueQrcode from "@chenfengyuan/vue-qrcode";
export default defineComponent({
  name: "QRModal",
  components: {
    VueQrcode: VueQrcode
  },
  props: {
    hospitalName: {
      type: String,
      required: true
    },
    isOpen: {
      type: Boolean,
      required: true
    },
    qrUrl: {
      type: String,
      required: true
    }
  },
  methods: {
    closeModal: function closeModal() {
      this.$emit("close-event");
    }
  }
});